// const local = `/${localStorage.lang}` || "";
const local =  "";
export default {
  noLogin: ["index", "about", "chia", "filecoin", "solana", "eth2", "staking"],
  about: `${local}/about`,

  index: `${local}/`,

  //   产品页面
  chia: `${local}/chia`,
  filecoin: `${local}/filecoin`,
  solana: `${local}/solana`,
  eth2: `${local}/eth2`,
  eos: `${local}/eos`,
  nem: `${local}/nem`,
  dot: `${local}/dot`,
  mina: `${local}/mina`,
  near: `${local}/near`,

  // staking 页面
  staking: `${local}/staking`,

  // 提现页面
  withdraw: `${local}/withdraw`,
  // 充币页面
  deposit: `${local}/deposit`,

  // 无权限
  noaccess: `${local}/other/noaccess`,

  // 敬请期待
  willopen: `${local}/other/willOpen`,

  //   用户中心
  user_center: `${local}/user`,

  // btcfx重置连接
  bfxreset: `/bfxreset`,

  // 密码重置连接
  pwdreset: `/pwdreset`,
  // 邀请链接
  invite: `/invite`,
};
