


/* eslint-disable */
const obj = {

 parseQuery: (str, separator) => {
    let  rquery = /^(?:[^?]*\?)?([\w\d\-\/=&%]+)/;
    let query = String(str).match(rquery),
        key,
        value;

    if (query == null) return "";

    query = query.pop();
    separator = separator || '&';

    return query.split(separator).reduce(function(hash, pair) {
        if (pair.indexOf('=') > 0) {
            pair = decodeURIComponent(pair).split('=');

            key = pair.shift();
            // 如果query中某个变量值包含等号
            // 我们应该重新组合起来
            value = pair.join('=');

            if (value != void 0) {
                value = value.replace('+', ' ');
            }
        } else {
            key = decodeURIComponent(pair);
            value = void 0;
        } 

        hash[key] = value;

        return hash;
    }, {});
},

shortStr: (str, prelen, taillen, separator) => {
    return (
      str.substring(0, prelen) + separator + str.substring(str.length - taillen)
    );
}

}

export default obj;

