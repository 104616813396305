const prefix = "/api"
const version = "/v1"

export default {
  /* ---------------account-----------------*/
  // register
  register: `${prefix}${version}/account/create`,
  // 邮箱登录step1
  login: `${prefix}${version}/account/login`,
  // 登录日志
  authorize_log: `${prefix}${version}/account/authorize_log`,
  // 获取验证码
  emailcode: `${prefix}${version}/account/email/code`,
  // 获取重置连接
  resetlink: `${prefix}${version}/account/email/resetlink`,
  // bfx重置密码
  bfxreset: `${prefix}${version}/account/bfxreset`,
  // 基本信息
  baseinfo: `${prefix}${version}/account/baseinfo`,
  // 资产
  asset: `${prefix}${version}/account/asset`,
  // 获取ga uri
  gauri: `${prefix}${version}/account/ga/uri`,
  // 验证及保存ga密码
  gabind: `${prefix}${version}/account/ga/bind`,
  // 单独验证ga
  gaverify: `${prefix}${version}/account/ga/verify`,
  // 更换密码
  updatepwduid: `${prefix}${version}/account/password`,
  // 忘记后重置密码
  forgetpwdreset: `${prefix}${version}/account/password/reset`,
  // 忘记后重置密码连接
  forgetpwdlink: `${prefix}${version}/account/password/resetlink`,
  // 更换邮箱
  updateemail: `${prefix}${version}/account/email/reset`,
  // 获取提币email验证码
  withdrawEmailVcode: `${prefix}${version}/account/withdraw/vcode`,
  // 提币双认证
  withdraw: `${prefix}${version}/account/withdraw/valid`,
  // 支持的提币列表
  withdrawTokenList: `${prefix}${version}/account/withdraw/tokens`,
  // 获取提币记录
  withdrawList: `${prefix}${version}/account/withdraw/list`,
  // 获取btcfx_asset指定币种余额
  bfxAssetSpecTokenBalance: `${prefix}${version}/account/balance`,
  // 获取指定币种提币限额
  withdrawLimit: `${prefix}${version}/account/withdraw/limit`,
  // 获取登陆记录
  loginList: `${prefix}${version}/account/login_info`,
  // 获取通知列表
  announceList: `${prefix}${version}/account/announce`,
  // 获取流水列表
  balanceFlowList: `${prefix}${version}/account/balance_flows`,
  // 获取提币记录
  depositList: `${prefix}${version}/account/deposit/list`,
  // 获取充值地址
  depositAddr: `${prefix}${version}/account/wallet/address`,
  // 是否内部地址 内部地址返回uid
  isInternalAddr: `${prefix}${version}/account/wallet/isInternalAddress`,
  // 获取地址簿信息
  getAddrBook: `${prefix}${version}/account/wallet/address_book/get`,
  // 增加地址簿信息
  addAddrBook: `${prefix}${version}/account/wallet/address_book/add`,
  // 删除地址簿信息
  delAddrBook: `${prefix}${version}/account/wallet/address_book/del`,
  /* ---------------product-----------------*/

  // fil1xyxgo
  filxyxgprofit: `${prefix}${version}/product/fil/xyxgo/profit`,
  // fil1xyxgo
  filxyxgsum: `${prefix}${version}/product/fil/xyxgo/sum`,
  // chia
  chiaprofit: `${prefix}${version}/product/chia/profit`,
  // fil定期
  filrebate: `${prefix}${version}/product/fil/rebate/profit`,
  // fil储蓄
  fildeposit: `${prefix}${version}/product/fil/deposit/profit`,
  // eth2
  eth2profit: `${prefix}${version}/product/eth2/profit`,
  // 收益列表
  filshare: `${prefix}${version}/product/share`,
  // eth2收益列表
  eth2share: `${prefix}${version}/product/eth2/share`,

  // 上传服务器
  upload: `/group1/upload`,

  // 更新或者插入kyc
  updateKyc: `${prefix}${version}/account/kyc`,
};