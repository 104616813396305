/* eslint-disable */
import getData from "../services/getData";
import Cookie from "../utils/cookie";
import strUtil from "../utils/string";
import md5 from "md5";
// import CONST from "../config/const";
import { message } from "../lib";
import route_map from "../config/route_map";
import helper from "../utils/helper";
// import API from "../config/api";
// import { FormData } from "isomorphic-form-data";

export default {
  namespace: "layout",
  state: {
    // 语言
    lang: "ja-jp",
    // 登陆窗口状态
    loginModal: false,
    registerModal: false,
    forgetModal: false,
    resetModal: false,
    pwdResetModal: false,
    bfxForgetModal: false,
    login2faModal: false,
    gaSettingModal: false,
    kycSettingModal: false,
    resetCode: "",
    depositAddress: "",
    withdrawAddressList: [],

    // 资产
    assets: [],
    filxyxgprofit: [],
    chiaprofit: [],
    filrebate: [],
    fildeposit: [],
    eth2profit: [],
    filxyxgsum: [],

    // 资产记录
    sharelist: [],

    // 提币记录列表
    withdrawlist: [],
    depositlist: [],
    toUserId: "",
    // 支持提币币种
    withdrawtokens: [],

    // 指定币种余额
    specTokenBalance: 0,

    // 指定提币token
    specWithdrawTokenId: "",
    // 指定提币限额
    withdrawLimit: {
      token_id: "",
      daily_withdraw_max: "0",
      withdrawed: "0",
    },

    // 支持提币币中map
    withdrawTokenInfoMap: {
      Updated: 0,
      Created: 0,
      token_id: "",
      token_name: "",
      token_full_name: "",
      icon_url: "",
      net_fee: "0",
      min_withdraw: "0",
      min_deposit: "0",
      daily_withdraw_max: "0",
      no_kyc_daily_withdraw_max: "0",
      allow_withdraw: false,
      allow_deposit: false,
    },

    // 通知列表
    announceList: [],

    // 首页通知
    indexAnnounceList: [],

    // 流水记录
    balanceFlowList: [],

    // 登陆列表
    loginList: [],

    // 登陆页面公告窗口
    announceModal: false,

    // 登陆用户的info
    userinfo: {
      user_id: "",
    },
    is_login: false,
    galink: "",
  },
  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {
      // eslint-disable-line
      history.listen((location) => {
        // 重置页面跳转
        let pathname = location.pathname;
        let search = location.search;
        let params = strUtil.parseQuery(search);
        // btcfx重置连接检测
        if (
          pathname.indexOf(route_map.bfxreset) > -1 &&
          !Cookie.read("user_id") &&
          params["code"]
        ) {
          dispatch({
            type: "save",
            payload: {
              loginModal: false,
              registerModal: false,
              forgetModal: false,
              resetModal: true,
              pwdResetModal: false,
              bfxForgetModal: false,
              resetCode: params["code"],
            },
          });
          window.sessionStorage.setItem("resetCode", params["code"]);
        }
        // 密码重置连接
        if (
          pathname.indexOf(route_map.pwdreset) > -1 &&
          !Cookie.read("user_id") &&
          params["code"]
        ) {
          dispatch({
            type: "save",
            payload: {
              loginModal: false,
              registerModal: false,
              forgetModal: false,
              resetModal: false,
              pwdResetModal: true,
              bfxForgetModal: false,
              resetCode: params["code"],
            },
          });
          window.sessionStorage.setItem("resetCode", params["code"]);
        }

        // 确保跳转后有email地址
        if (
          pathname == route_map.index &&
          window.sessionStorage.getItem("userinfo")
        ) {
          let userinfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
          dispatch({
            type: "save",
            payload: {
              userinfo,
            },
          });
        }

        // 确保用户在主页清空了cookie自动退出
        if (pathname == route_map.index && !Cookie.read("user_id")) {
          dispatch({
            type: "save",
            payload: {
              is_login: false,
            },
          });
        }

        // 过期登出

        if (
          pathname.indexOf(route_map.user_center) > -1 &&
          !Cookie.read("user_id")
        ) {
          window.sessionStorage.removeItem("userinfo");
          window.location.href = route_map.index;
        }
        if (
          pathname.indexOf(route_map.withdraw) > -1 &&
          !Cookie.read("user_id")
        ) {
          window.sessionStorage.removeItem("userinfo");
          window.location.href = route_map.index;
        }
        // 提币页面初始化
        if (
          pathname.indexOf(route_map.withdraw) > -1 &&
          Cookie.read("user_id")
        ) {
          dispatch({
            type: "getWithdrawtokens",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getWithdrawList",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getBalanceFLowsList",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getUserInfo",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
        }
        // 充值页面初始化
        if (
          pathname.indexOf(route_map.deposit) > -1 &&
          Cookie.read("user_id")
        ) {
          dispatch({
            type: "getWithdrawtokens",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getDepositList",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getUserInfo",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
        }

        // 用户中心连接检查
        if (
          pathname.indexOf(route_map.user_center) > -1 &&
          Cookie.read("user_id")
        ) {
          dispatch({
            type: "getUserInfo",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "asset",
            payload: {
              user_id: Cookie.read("user_id"),
            },
          });
          dispatch({
            type: "getLoginList",
          });
        }
        // console.log(route_map.index);
        // 首页通知
        let patharray = pathname.split("/");

        if (pathname.indexOf(route_map.index) != -1 && patharray.length > 1) {
          dispatch({
            type: "getAnnounceList",
            payload: {
              sort: "order",
              limit: 5,
              loginTime: 0,
            },
          });
        }
      });
    },
  },
  effects: {
    // 获取基本信息
    *getUserInfo({ errorCallback, errorClear }, { call, put }) {
      if (!Cookie.read("user_id")) {
        yield put({
          type: "save",
          payload: {
            is_login: false,
          },
        });
      }
      const result = yield call(getData("baseinfo"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        message.success("❤️ MiPoolへようこそ！");
        yield put({
          type: "save",
          payload: {
            userinfo: result.data,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
        Cookie.del("user_id");
        window.sessionStorage.removeItem("userinfo");
        window.location.href = route_map.index;
      }
    },
    // 获取btcfx重置密码
    *getResetLink(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      const result = yield call(getData("resetlink"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        message.success(
          "お客様宛てにパスワードを再設定のメールを送信しました。お待ちください。 📧 "
        );
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 获取重置密码连接
    *getPwdResetLink(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      const result = yield call(getData("forgetpwdlink"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        message.success(
          "お客様宛てにパスワードを再設定のメールを送信しました。お待ちください。 📧 "
        );
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 登陆
    *login(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      data.password = md5(payload.password);
      const result = yield call(getData("login"), {
        payload: data,
        method: "post",
      });

      // GA二次认证
      if (result.code === "OK" && result.data.data.token === "GA") {
        window.sessionStorage.userinfo = JSON.stringify(result.data.data);
        yield put({
          type: "save",
          payload: {
            login2faModal: true,
            loginModal: false,
          },
        });
      } else if (result.code === "OK") {
        // console.log(result.data.data);
        window.sessionStorage.userinfo = JSON.stringify(result.data.data);
        Cookie.write({
          name: "user_id",
          value: String(result.data.data.user_id),
        });

        yield put({
          type: "save",
          payload: {
            userinfo: result.data.data,
          },
        });
        successCallback && successCallback();
        if (redirect && helper.sameDomain(redirect)) {
          const url = helper.filterRedirect(decodeURIComponent(redirect));
          window.location.href = url;
        } else {
          window.location.href = route_map.user_center;
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 获取email验证码
    *getEmailCode(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      const result = yield call(getData("emailcode"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        if (data.type == "reset" || data.type == "pwdreset") {
          message.success(
            "お客様宛てにパスワードを再設定のメールを送信しました。お待ちください。 📧 "
          );
        } else {
          message.success(
            "お客様宛てにメールを送信しました。お待ちください。 📧 "
          );
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    // 通过连接重置密码
    *bfxReset(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      data.password = md5(payload.password);
      const result = yield call(getData("bfxreset"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        message.success("Succ! Plz Enjoy~ ");
        yield put({
          type: "save",
          payload: {
            loginModal: true,
            registerModal: false,
            forgetModal: false,
            resetModal: false,
            bfxForgetModal: false,
          },
        });
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 注册
    *register(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      data.password = md5(payload.password);
      const result = yield call(getData("register"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        message.success("Succ! Plz Enjoy~ ");
        yield put({
          type: "save",
          payload: {
            loginModal: true,
            registerModal: false,
            forgetModal: false,
            resetModal: false,
            bfxForgetModal: false,
          },
        });
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 通过连接重置密码
    *pwdReset(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      data.new = md5(payload.new);
      const result = yield call(getData("forgetpwdreset"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        message.success("Succ! Plz Enjoy~ ");
        yield put({
          type: "save",
          payload: {
            loginModal: true,
            registerModal: false,
            forgetModal: false,
            resetModal: false,
            bfxForgetModal: false,
            pwdResetModal: false,
          },
        });
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 密码替换
    *updatePwd(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      data.new = md5(payload.new);
      data.old = md5(payload.old);
      data.user_id = Cookie.read("user_id");
      const result = yield call(getData("updatepwduid"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        successCallback();
        yield put({
          type: "save",
          payload: {
            is_login: false,
          },
        });
        Cookie.del("user_id");
        window.sessionStorage.removeItem("userinfo");
        // setTimeout(() => {
        //   window.location.href = route_map.index;
        // }, 4000);
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // Email替换
    *updateEmail(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      let data = payload;
      const result = yield call(getData("updateemail"), {
        payload: data,
        method: "post",
      });

      if (result.code === "OK") {
        successCallback();
        yield put({
          type: "save",
          payload: {
            is_login: false,
          },
        });
        Cookie.del("user_id");
        window.sessionStorage.removeItem("userinfo");
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // 获取账户btcfx资产
    *asset({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("asset"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            assets: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    // 获取账户filxyxgprofit资产
    *filxyxgprofit({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("filxyxgprofit"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            filxyxgprofit: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    // 获取账户filxyxgprofit资产汇总
    *filxyxgsum({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("filxyxgsum"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            filxyxgsum: result.data.data,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // chiaprofit
    *chiaprofit({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("chiaprofit"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            chiaprofit: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // filrebate
    *filrebate({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("filrebate"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            filrebate: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // fildeposit
    *fildeposit({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("fildeposit"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            fildeposit: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    // eth2profit
    *eth2profit({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("eth2profit"), {
        payload: {
          user_id: Cookie.read("user_id"),
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            eth2profit: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    // sharelist
    *sharelist({ payload, errorCallback, errorClear }, { call, put }) {
      let result = {};
      if ([1, 4, 5].indexOf(payload.item_id) > -1) {
        result = yield call(getData("filshare"), {
          payload: {
            user_id: Cookie.read("user_id"),
            item_id: payload.item_id,
          },
          method: "get",
        });
      } else {
        result = yield call(getData("eth2share"), {
          payload: {
            user_id: Cookie.read("user_id"),
          },
          method: "get",
        });
      }

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            sharelist: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getGaUri({ payload, errorCallback, errorClear }, { call, put }) {
      let result = {};

      result = yield call(getData("gauri"), {
        payload: {
          email: JSON.parse(window.sessionStorage.getItem("userinfo"))["email"],
        },
        method: "get",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            galink: result.data.data,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *GaBind(
      { payload, successCallback, errorCallback, errorClear },
      { call, put }
    ) {
      let result = {};

      result = yield call(getData("gabind"), {
        payload: {
          email: JSON.parse(window.sessionStorage.getItem("userinfo"))["email"],
          gcode: payload.gcode,
          vcode: payload.vcode,
        },
        method: "post",
      });

      if (result.code === "OK") {
        if (result.data.data == true) {
          successCallback && successCallback();
        } else {
          errorCallback && errorCallback(errorClear, result.code, result.msg);
          message.error("GA Code Mismatch");
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *GaVerify(
      { payload, successCallback, errorCallback, errorClear },
      { call, put }
    ) {
      let result = {};

      result = yield call(getData("gaverify"), {
        payload: {
          email: JSON.parse(window.sessionStorage.getItem("userinfo"))["email"],
          gcode: payload.gcode,
        },
        method: "post",
      });

      if (result.code === "OK") {
        if (payload.type === "2fa") {
          window.sessionStorage.userinfo = JSON.stringify(result.data.data);
          Cookie.write({
            name: "user_id",
            value: String(result.data.data.user_id),
          });

          yield put({
            type: "save",
            payload: {
              userinfo: result.data.data,
            },
          });
          successCallback();

          window.location.href = route_map.user_center;
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *KycUpdate(
      { payload, successCallback, errorCallback, errorClear },
      { call, put }
    ) {
      let result = {};

      result = yield call(getData("updateKyc"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        if (result.data.data > 0) {
          successCallback && successCallback();
        } else {
          errorCallback && errorCallback(errorClear, result.code, result.msg);
          message.error("Update Error");
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    // 获取提币email验证码
    *getWithdrawEmailCode(
      {
        payload,
        history,
        redirect,
        successCallback,
        errorCallback,
        errorClear,
      },
      { call, put }
    ) {
      const result = yield call(getData("withdrawEmailVcode"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        message.success(
          "お客様宛てにメールを送信しました。お待ちください。 📧 "
        );
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    *withdrawValid(
      { payload, successCallback, errorCallback, errorClear },
      { call, put }
    ) {
      let result = {};

      result = yield call(getData("withdraw"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getBalance(
      { payload, successCallback, errorCallback, errorClear },
      { call, put }
    ) {
      let result = {};

      result = yield call(getData("bfxAssetSpecTokenBalance"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        yield put({
          type: "save",
          payload: {
            specTokenBalance: result.data.data,
          },
        });
        // successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getWithdrawList({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("withdrawList"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            withdrawlist: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getDepositList({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("depositList"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            depositlist: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getWithdrawtokens({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("withdrawTokenList"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            withdrawtokens: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getWithdrawLimit({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("withdrawLimit"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            withdrawLimit: result.data.data,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getLoginList({ errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("loginList"), {
        payload: {
          user_id: Cookie.read("user_id"),
          page: 1,
          sort: "login_time",
          limit: 5,
          direction: "desc",
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            loginList: result.data.data.list,
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getAnnounceList({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("announceList"), {
        payload: {
          page: 1,
          sort: payload.sort || "created",
          limit: payload.limit || 100,
          direction: "desc",
          created: payload.loginTime,
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);

        if (payload.sort == "order") {
          yield put({
            type: "save",
            payload: {
              indexAnnounceList: result.data.data.list || [],
            },
          });
        } else {
          yield put({
            type: "save",
            payload: {
              announceList: result.data.data.list || [],
            },
          });
        }
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getBalanceFLowsList(
      { payload, errorCallback, errorClear },
      { call, put }
    ) {
      const result = yield call(getData("balanceFlowList"), {
        payload: {
          page: 1,
          sort: "created",
          limit: 50,
          direction: "desc",
          user_id: Cookie.read("user_id"),
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            balanceFlowList: result.data.data.list || [],
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *getDepositAddress({ payload, errorCallback, errorClear }, { call, put }) {
      const result = yield call(getData("depositAddr"), {
        payload: {
          token_id: payload.token_id,
          user_id: Cookie.read("user_id"),
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            depositAddress: result.data.data.Address || "",
          },
        });
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *isInternalAddr(
      { payload, errorCallback, errorClear, successCallback },
      { call, put }
    ) {
      const result = yield call(getData("isInternalAddr"), {
        payload,
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            toUserId: result.data.data || "",
          },
        });
        successCallback && successCallback(result.data.data);
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    *getAddrBook(
      { payload, errorCallback, errorClear, successCallback },
      { call, put }
    ) {
      const result = yield call(getData("getAddrBook"), {
        payload: {
          user_id: Cookie.read("user_id"),
          token_id: payload.token_id,
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        yield put({
          type: "save",
          payload: {
            withdrawAddressList: result.data.data.list || [],
          },
        });
        successCallback && successCallback(result.data.data.list);
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
    *addAddrBook(
      { payload, errorCallback, errorClear, successCallback },
      { call, put }
    ) {
      const result = yield call(getData("addAddrBook"), {
        payload: {
          user_id: Cookie.read("user_id"),
          token_id: payload.token_id,
          type: payload.type,
          tag: payload.tag,
          address: payload.address,
          gcode: payload.gcode,
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        message.success("Added Succ!");
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },

    *delAddrBook(
      { payload, errorCallback, errorClear, successCallback },
      { call, put }
    ) {
      const result = yield call(getData("delAddrBook"), {
        payload: {
          id: payload.id,
          user_id: Cookie.read("user_id"),
        },
        method: "post",
      });

      if (result.code === "OK") {
        // console.log(result);
        message.success("Del Succ!");
        successCallback && successCallback();
      } else {
        errorCallback && errorCallback(errorClear, result.code, result.msg);
        message.error(result.msg);
      }
    },
  },
};
