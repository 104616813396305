import React from 'react';
import {Router, Route, Switch} from 'dva/router';
import dynamic from 'dva/dynamic';
import route_map from './config/route_map';

// 懒加载
function RouterConfig({history, app}) {
 
  // 404
  const NotFountRC = dynamic({
    app,
    component: () => import("./routes/404"),
  });

  // 首页
  const IndexRC = dynamic({
    app,
    component: () => import("./routes/index/index"),
  });
  // chia
  const ChiaRC = dynamic({
    app,
    component: () => import("./routes/product/chia"),
  });
  // filecoin
  const FilRC = dynamic({
    app,
    component: () => import("./routes/product/filecoin"),
  });
  // solana
  const SolRC = dynamic({
    app,
    component: () => import("./routes/product/solana"),
  });
  // eth2
  const EosRC = dynamic({
    app,
    component: () => import("./routes/product/eos"),
  });
  // eth2
  const NemRC = dynamic({
    app,
    component: () => import("./routes/product/nem"),
  });
  // eth2
  const PolkadotRC = dynamic({
    app,
    component: () => import("./routes/product/polkadot"),
  });
  // eth2
  const MinaRC = dynamic({
    app,
    component: () => import("./routes/product/mina"),
  });
  // eth2
  const Eth2RC = dynamic({
    app,
    component: () => import("./routes/product/eth2"),
  });
  // eth2
  const NearRC = dynamic({
    app,
    component: () => import("./routes/product/near"),
  });
//   关于页面
  const AboutRC = dynamic({
    app,
    component: () => import("./routes/about/about"),
  });
//  staking页面
  const StakingRC = dynamic({
    app,
    component: () => import("./routes/staking/staking")
  })
/****************需要登陆的页面********************************/
  const UserCenterRC = dynamic({
    app,
    component: () => import("./routes/usercenter/user"),
  });

  const WithdrawRC = dynamic({
    app,
    component: () => import("./routes/withdraw/withdraw")
  })

  const DepositRC = dynamic({
    app,
    component: () => import("./routes/deposit/deposit")
  })


return (
  <Router history={history}>
    <Switch>
      {/* 产品页面 */}
      <Route exect path={route_map.chia} component={ChiaRC} key="chia" />
      <Route exect path={route_map.filecoin} component={FilRC} key="filecoin" />
      <Route exect path={route_map.eth2} component={Eth2RC} key="eth2" />
      <Route exect path={route_map.solana} component={SolRC} key="solana" />
      <Route exect path={route_map.eos} component={EosRC} key="eos" />
      <Route exect path={route_map.dot} component={PolkadotRC} key="dot" />
      <Route exect path={route_map.nem} component={NemRC} key="nem" />
      <Route exect path={route_map.mina} component={MinaRC} key="mina" />
      <Route exect path={route_map.near} component={NearRC} key="near" />
      {/* 关于 */}
      <Route exect path={route_map.about} component={AboutRC} key="about" />
      {/* staking 页面*/}
      <Route exect path={route_map.staking} component={StakingRC} key="staking" />
      {/* 用户中心 */}
      <Route
        exect
        path={route_map.user_center}
        component={UserCenterRC}
        key="user_center"
      />
      {/* 提币 */}
      <Route
        exect
        path={route_map.withdraw}
        component={WithdrawRC}
        key="withdraw"
      />
      {/* 充币 */}
      <Route
        exect
        path={route_map.deposit}
        component={DepositRC}
        key="deposit"
      />

      {/* 首页  不能放到路由顶端 否则所有路由都跟这个匹配*/}
      <Route exect path={route_map.index} component={IndexRC} key="index" />
      <Route component={NotFountRC} key="not_fount" />
    </Switch>
  </Router>
);

}

export default RouterConfig;