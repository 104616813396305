import dva from 'dva'
import { createBrowserHistory } from "history";
import createLoading from "dva-loading";
import layout from './models/layout';


import "./i18n";

import "./assets/scss/style.scss";
// routes
import Routes from "./router";


// 1. Initialize
  const data = {
    history: createBrowserHistory(),
    //onAction: createLogger(),
    onError(e) {
      window.console.log(e);
    },
  };
  const app = dva(data);

  // 2. Plugins
  app.use(createLoading());

  // 3. 全局models
  app.model(layout);

  // 4. Router
  app.router(Routes);

  app.start("#root");



